import request from "@/utils/request"

// 合作伙伴列表一览
export function listPage(data) {
  return request({
    url: "/partner/partnerPage",
    method: "post",
    data
  })
}
//合作伙伴基本信息新增
export function save(data) {
  return request({
    url: "partner/save",
    method: "post",
    data
  })
}
//合作伙伴基本信息编辑
export function update(data) {
  return request({
    url: "/partner/update",
    method: "post",
    data
  })
}
//查询合作伙伴详情
export function selectPartnerById(query) {
  return request({
    url: "/partner/selectPartnerById",
    method: "get",
    params: query
  })
}
//获取账号管理的合作伙伴的基础信息
export function getByPartnerId(query) {
  return request({
    url: "/partner/getByPartnerId",
    method: "get",
    params: query
  })
}
//账号管理列表
export function partnerAccountTwoPage(query) {
  return request({
    url: "/orgUserGinseng/listPage",
    method: "get",
    params: query
  })
}
//修改账号周期
export function updatePartnerAccount(data) {
  return request({
    url: "/partner/updatePartnerAccount",
    method: "post",
    data
  })
}
//关联企业列表
export function partnerCompanyPage(data) {
  return request({
    url: "/partner/partnerCompanyPage",
    method: "post",
    data
  })
}
//冻结合作伙伴的用户账号
export function notDelete(query) {
  return request({
    url: "/orgUserGinseng/notDelete",
    method: "get",
    params: query
  })
}
//导出合作伙伴
export function exportExcel(data) {
  return request({
    url: "/partner/exportExcel",
    method: "post",
    responseType: "blob",
    data
  })
}
//新增合作伙伴的用户账号
export function insertUser(data) {
  return request({
    url: "/orgUserGinseng/save",
    method: "post",
    data
  })
}
//修改(总台)
export function updateUsers(data) {
  return request({
    url: "/orgUserGinseng/update",
    method: "post",
    data
  })
}


//查询系统角色
export function listAll() {
  return request({
    url: "/admin_role/v1/listAll",
    method: "post",

  })
}
//根据关键字模糊查询智参中心数据
export function getWisdomGinsengList(query) {
  return request({
    url: "/wisdomGinseng/getWisdomGinsengList",
    method: "get",
    params: query
  })
}
//智参中心一览
export function wisdomGinsengPage(data) {
  return request({
    url: "/wisdomGinseng/wisdomGinsengPage",
    method: "post",
    data
  })
}
//基本智参信息新增
export function addwisdomGinsengPage(data) {
  return request({
    url: "/wisdomGinseng/insertWisdomGinseng",
    method: "post",
    data
  })
}
//查询详情
export function getWisdomGinsengDetail(query) {
  return request({
    url: "/wisdomGinseng/getWisdomGinsengDetail",
    method: "get",
    params: query
  })
}
//编辑·智参中心
export function updateWisdomGinseng(data) {
  return request({
    url: "/wisdomGinseng/updateWisdomGinseng",
    method: "post",
    data
  })
}
//关联企业数据一览
export function wisdomGinsengCompanyPage(data) {
  return request({
    url: "/wisdomGinseng/wisdomGinsengCompanyPage",
    method: "post",
    data
  })
}
//服务建议列表
export function selectSuggestionPage(data) {
  return request({
    url: "/partnerSuggestion/selectSuggestionPage",
    method: "post",
    data
  })
}
//新增服务建议
export function addSuggestion(data) {
  return request({
    url: "/partnerSuggestion/insertSuggestion",
    method: "post",
    data
  })
}
//编辑服务建议
export function updateSuggestion(data) {
  return request({
    url: "/partnerSuggestion/updateSuggestion",
    method: "post",
    data
  })
}
//服务建议
export function getOne(query) {
  return request({
    url: "/partnerSuggestion/getOne",
    method: "get",
    params: query
  })
}
//分配企业一览
export function getSuggestionCompanyPage(data) {
  return request({
    url: "/partnerSuggestion/getSuggestionCompanyPage",
    method: "post",
    data
  })
}
//给服务建议分配企业
export function updateCompanyParam(data) {
  return request({
    url: "/partnerSuggestion/updateSuggestionCompanyParam",
    method: "post",
    data
  })
}
//服务建议下架
export function offShelf(query) {
  return request({
    url: "/partnerSuggestion/offShelf",
    method: "get",
    params: query
  })
}
//服务建议上架
export function onShelf(query) {
  return request({
    url: "/partnerSuggestion/onShelf",
    method: "get",
    params: query
  })
}
//冻结/取消冻结
export function updateIsFreeze(query) {
  return request({
    url: "/ServerIssueMechanism/updateIsFreeze",
    method: "get",
    params: query
  })
}
//城市合伙人关联的合作伙伴一览
export function cityPartnerPage(data) {
  return request({
    url: "/partner/cityPartnerPage",
    method: "post",
    data
  })
}
//城市合伙人关联企业
export function associateCompany(data) {
  return request({
    url: "/partner/associateCompany",
    method: "post",
    data
  })
}
//查询所有的合作伙伴数据除城市合伙人外
export function getAllPartner(data) {
  return request({
    url: "/partner/getAllPartner",
    method: "post",
    data
  })
}
//解除绑定关联
export function unbindPartner(query) {
  return request({
    url: "/partner/unbindPartner",
    method: "get",
    params: query
  })
}
//角色一览查询(总台)
export function orgRoleGinseng(query) {
  return request({
    url: "/orgRoleGinseng/listPage",
    method: "get",
    params: query
  })
}
//角色新增(总台)
export function saverole(data) {
  return request({
    url: "/orgRoleGinseng/save",
    method: "post",
    data
  })
}
//角色修改(总台)
export function updaterole(data) {
  return request({
    url: "/orgRoleGinseng/update",
    method: "post",
    data
  })
}
//角色详情
export function listById(query) {
  return request({
    url: "/orgRoleGinseng/listById",
    method: "get",
    params: query
  })
}
//角色删除
export function deleteById(query) {
  return request({
    url: "/orgRoleGinseng/deleteById",
    method: "DELETE",
    params: query
  })
}
//添加角色权限
export function addRoleAuth(data) {
  return request({
    url: "/orgRoleAuthGinseng/addRoleAuth",
    method: "POST",
    data
  })
}
//根据合作伙伴编号查询该合作机构最高权限的角色编号
export function roleNotAuthority(query) {
  return request({
    url: "/orgRoleGinseng/roleNotAuthority",
    method: "get",
    params: query
  })
}
//根据角色编号查询权限(总台)
export function getRoleAuth(query) {
  return request({
    url: "/orgAuthGinseng/getRoleAuth",
    method: "get",
    params: query
  })
}
//账号启用/禁用
export function notState(query) {
  return request({
    url: "/orgUserGinseng/notState",
    method: "get",
    params: query
  })
}
//查询用户详情
export function accoutlistById(query) {
  return request({
    url: "/orgUserGinseng/listById",
    method: "get",
    params: query
  })
}
//用于用户新增选择角色(总台)
export function listRoleGinseng(query) {
  return request({
    url: "/orgRoleGinseng/listRoleGinseng",
    method: "get",
    params: query
  })
}
//修改角色权限(普通权限)
export function editPartnerRoleAuth(data) {
  return request({
    url: "/orgRoleAuthGinseng/editPartnerRoleAuth",
    method: "post",
    data
  })
}
//修改角色权限(最高权限)
export function editRoleAuth(data) {
  return request({
    url: "/orgRoleAuthGinseng/editRoleAuth",
    method: "post",
    data
  })
}
//账号是否可登录h5状态
export function isHFour(query) {
  return request({
    url: "/orgUserGinseng/isHFour",
    method: "get",
    params: query
  })
}

// 导出合作伙伴下的企业信息
export function exportPartnerExcel(data) {
  return request({
    url: "/partner/exportPartnerExcel",
    method: "post",
    responseType: "blob",
    data
  })
}





